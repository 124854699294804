
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import { maskWalletAddress } from "@/shared/utils/stringHelper";
import { useAccount } from "@/shared/composables/Profile/useAccount";

export default defineComponent({
  emits: ["on-logout-success"],
  props: {
    alwaysShow: Boolean,
  },
  setup(_props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { isPartialAuthenticated } = useAccount();

    // TODO: Maybe add prompt about logging out
    const handleLogout = async () => {
      registrationSimpleStore.resetStore();

      // needs to call this because auth token is present on the storage
      await store.dispatch("logoutSuccess");

      // emit on logout success
      emit("on-logout-success");
    };

    return {
      t,
      registrationSimpleStore,
      walletAddressMasked: computed(() =>
        maskWalletAddress(registrationSimpleStore.stakingId)
      ),
      isPartialAuthenticated,
      handleLogout,
    };
  },
  components: {},
});
