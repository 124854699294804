import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "top-header-logout"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_ctx.alwaysShow || _ctx.isPartialAuthenticated)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.registrationSimpleStore.stakingId)
          ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
              key: 0,
              class: "mb-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.walletAddressMasked), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_a_button, {
          onClick: _ctx.handleLogout,
          type: "link",
          size: "small",
          class: "p-0 top-header-logout__button",
          style: {"height":"1.5rem"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Log out")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]))
    : _createCommentVNode("", true)
}