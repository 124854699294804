import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "not-found" }
const _hoisted_2 = { class: "not-found__header-action" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopHeaderLogout = _resolveComponent("TopHeaderLogout")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FullPageCardLayout, null, {
      "header-right": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createBlock(_component_TopHeaderLogout, {
                key: 0,
                class: "not-found__header-action-logout",
                alwaysShow: _ctx.isAuthenticated
              }, null, 8, ["alwaysShow"]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_result, {
          class: "whitespace-pre-line",
          title: _ctx.title,
          "sub-title": _ctx.subTitle
        }, {
          icon: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.iconSrc,
              class: "not-found__icon"
            }, null, 8, _hoisted_3)
          ]),
          extra: _withCtx(() => [
            _createVNode(_component_a_button, {
              onClick: _ctx.goToHomePage,
              class: "not-found__home-page-button mt-28"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("To home page")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["title", "sub-title"])
      ]),
      _: 1
    })
  ]))
}