
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import PageNotFoundIcon from "@/assets/page-not-found.svg";
import routeNames from "@/shared/router/routeNames";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import { useAccount } from "@/shared/composables/Profile/useAccount";
import TopHeaderLogout from "@/web/views/Register/TopHeaderLogout.vue";
import { registrationSimpleStore } from "@/web/store/registrationStore";

export default defineComponent({
  props: {
    iconSrc: {
      type: String,
      default: PageNotFoundIcon,
    },
    title: String,
    subTitle: String,
  },
  setup() {
    const t = useI18n().t;
    const router = useRouter();
    const { isAuthenticated, selectedUserStakingKeyHash } = useAccount();

    onMounted(() => {
      /**
       * Register top header were re-used
       * On mount check if there's an authenticated user
       * and save staking key hash on register staking ID
       */
      if (isAuthenticated.value && selectedUserStakingKeyHash.value) {
        registrationSimpleStore.stakingId = selectedUserStakingKeyHash.value;
      }
    });

    const goToHomePage = () => {
      router.push({ name: routeNames.home });
    };

    return {
      t,
      router,
      goToHomePage,
      PageNotFoundIcon,
      isAuthenticated,
    };
  },
  components: {
    FullPageCardLayout,
    TopHeaderLogout,
  },
});
