
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageNotFoundIcon from "@/assets/page-not-found.svg";
import NotFound from "@/shared/components/NotFound.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;

    return {
      t,
      PageNotFoundIcon,
    };
  },
  components: {
    NotFound,
  },
});
